<template>
    <div>
        <div style="float: right;margin-right: 20px;" @click="onClose">
            <svg t="1678705360524" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2781" width="20" height="20"><path d="M512 0C229.187368 0 0 229.187368 0 512 0 794.812632 229.187368 1024 512 1024 794.812632 1024 1024 794.812632 1024 512 1024 229.187368 794.812632 0 512 0ZM512 970.105263C259.368421 970.105263 53.894737 764.631579 53.894737 512 53.894737 259.368421 259.368421 53.894737 512 53.894737 764.631579 53.894737 970.105263 259.368421 970.105263 512 970.105263 764.631579 764.631579 970.105263 512 970.105263ZM550.130526 512 719.629474 342.366316C730.273684 331.856842 730.273684 314.88 719.629474 304.370526 709.12 293.726316 692.143158 293.726316 681.633684 304.370526L512 473.869474 342.366316 304.370526C331.856842 293.726316 314.88 293.726316 304.370526 304.370526 293.726316 314.88 293.726316 331.856842 304.370526 342.366316L473.869474 512 304.370526 681.633684C293.726316 692.143158 293.726316 709.12 304.370526 719.629474 309.625263 724.884211 316.496842 727.578947 323.368421 727.578947 330.24 727.578947 337.111579 724.884211 342.366316 719.629474L512 550.130526 681.633684 719.629474C686.888421 724.884211 693.76 727.578947 700.631579 727.578947 707.503158 727.578947 714.374737 724.884211 719.629474 719.629474 730.273684 709.12 730.273684 692.143158 719.629474 681.633684L550.130526 512Z" p-id="2782"></path></svg>
        </div>
        <div style="font-size: 20px; font-weight: 600; text-align: center; margin: 10px 0 20px 0">
            <div>添加基本账户</div>
        </div>
        <div style="padding: 30px">
            <el-form :model="accountInfo" :rules="accountRules" ref="accountForm" class="demo-ruleForm">
                <div style="display: flex; margin-bottom: 40px">
                    <el-form-item label="身份证正面" prop="idCardFace">
                        <div style="width:360px;height:180px;">
                            <el-upload accept="image/*" class="upload-demo" :show-file-list=false drag multiple action="" :http-request="uploadImg">
                                <img v-if="accountInfo.idCardFace" :src="accountInfo.idCardFace" width="100%" height="180px">
                                <div>
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                                </div>
                            </el-upload>
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证背面" prop="idCardBack" style="margin-left: 40px">
                        <div style="width:360px;height:180px;">
                            <el-upload accept="image/*"  class="upload-demo" :show-file-list=false drag multiple action="" :http-request="uploadBack">
                                <img v-if="accountInfo.idCardBack" :src="accountInfo.idCardBack" width="100%" height="180px">
                                <div>
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                                </div>
                            </el-upload>
                        </div>
                    </el-form-item>
                </div>

                <div style="display: flex; margin-bottom: 40px">
                    <el-form-item label="开户许可证" prop="idCarlicense">
                        <div style="width:360px;height:180px;">
                            <el-upload accept="image/*" class="upload-demo" :show-file-list=false drag multiple action="" :http-request="uploadImgs">
                                <img v-if="accountInfo.idCarlicense" :src="accountInfo.idCarlicense" width="100%" height="180px">
                                <div>
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                                </div>
                            </el-upload>
                        </div>
                    </el-form-item>
                </div>

                <el-form-item label="开户银行" label-width="140px" prop="bankCode">
                    <el-select
                        v-model="accountInfo.bankCode"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="搜索银行"
                        :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option
                            v-for="item in options"
                            :key="item.bankCode"
                            :label="item.bankName"
                            :value="item.bankCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="银行行号" label-width="140px" prop="cardNo">
                    <el-input v-model="accountInfo.cardNo" style="width: 70%"></el-input>
                </el-form-item>
                <el-form-item label="法人手机号" label-width="140px" prop="phone">
                    <el-input v-model="accountInfo.phone" style="width: 70%" maxlength="11"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div style="display: flex;justify-content: center; padding-bottom: 20px">
            <!--    确定添加银行卡  -->
            <el-button type="primary" @click="affirm">确认添加</el-button>
        </div>
    </div>
</template>

<script>
export default{
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    props:{
        onClose:{
            type:Function,
            default(){
                return function (){}
            }
        }
    },
    data() {
        return {
            loading: false,
            options: [],
            accountInfo: {
                idCardFace: '',
                idCardBack: '',
                idCarlicense: '',
                bankCode: '',
                cardNo: '',
                phone: ''
            },
            accountRules: {
                idCardFace: [
                    { required: true, message: '请上传身份证正面图片', trigger: 'blur' },
                ],
                idCardBack: [
                    { required: true, message: '请上传身份证反面图片', trigger: 'blur' },
                ],
                idCarlicense: [
                    { required: true, message: '请上传开户许可证图片', trigger: 'blur' },
                ],
                bankCode: [
                    { required: true, message: '请选择开户银行', trigger: 'blur' },
                ],
                cardNo: [
                    { required: true, message: '银行行号不能为空', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '手机号码不能为空', trigger: 'blur' },
                    { pattern: /^1[3|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确手机号码', trigger: 'blur'},
                ],
            },
        };
    },
    mounted() {
    },
    methods:{
        // 搜索银行
        remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.newApi.getBankList({name: query}).then(res => {
                        this.options = res.data
                    })
                }, 200);
            } else {
                this.options = [];
            }
        },

        // 上传身份证正面
        uploadImg(params) {
            var file = params.file;
            var that = this;
            that.utils.upload(file, function (url) {
                if (!url){
                    return false;
                }
                that.accountInfo.idCardFace = url.url;
            })
        },

        // 上传开户许可证
        uploadImgs(params) {
            var file = params.file;
            var that = this;
            that.utils.upload(file, function (url) {
                if (!url){
                    return false;
                }
                that.accountInfo.idCarlicense = url.url;
            })
        },

        // 上传身份证反面
        uploadBack(params) {
            var file = params.file;
            var that = this;
            that.utils.upload(file, function (url) {
                if (!url){
                    return false;
                }
                that.accountInfo.idCardBack = url.url;
            })
        },

        //   添加机构账户
        affirm() {
            this.$refs['accountForm'].validate((valid) => {
                if (valid) {
                    this.openLoading("处理中稍后");
                    this.newApi.corpMemberCreate(this.accountInfo).then((res) => {
                        if (res.isSuccess == 1) {
                            this.utils.sus(res.data);
                            this.onClose();
                            this.$emit('change');
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>